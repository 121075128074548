@import './variables.css';

.marker-menu {
    position: absolute;
    bottom: 20vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--secondary-color);
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: auto;
}

.menu-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    margin: 5px;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9em;
    width: 80px; /* Adjust the width as needed */
    text-align: center;
}

.menu-button:hover {
    background-color: var(--primary-hover-color);
}

.menu-icon {
    font-size: 1.5em;
    margin-bottom: 5px;
}