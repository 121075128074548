@import './variables.css';

.settings-card {
    background: var(--secondary-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 100%;
    max-width: 600px;
    text-align: center;
}

.settings-card h3 {
    margin-bottom: 20px;
}

.settings {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.settings button {
    width: 80%;
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    background: var(--primary-color);
    color: var(--secondary-color);
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.settings button:hover {
    background: var(--primary-hover-color);
}