@import './variables.css';

.comment {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.comment-photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.comment-text {
    display: flex;
    flex-direction: column;
}

.comment-date {
    font-size: 0.8em;
    color: rgb(128, 128, 128);
}

.comment:last-child {
    border-bottom: none;
}

.menu-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
}

.menu-button:hover {
    background-color: var(--primary-hover-color);
}

.user-location-icon {
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
}