@import './variables.css';

.user-card {
    background: var(--secondary-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
    position: relative;
}

.user-card-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.photo-container {
    position: relative;
    display: inline-block;
}

.user-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.user-photo:hover {
    transform: scale(1.1);
}

.username-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.username {
    font-size: 1.5em;
    cursor: pointer;
}

.username-edit {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.username-input {
    font-size: 1.5em;
    text-align: center;
    border: none;
    border-bottom: 2px solid #ccc;
    outline: none;
    width: 100%;
    max-width: 200px;
    margin-bottom: 10px;
}

.username-buttons {
    display: flex;
    gap: 10px;
}

.save-button, .cancel-button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background: #4CAF50;
    color: white;
    cursor: pointer;
    transition: background 0.3s ease;
}

.cancel-button {
    background: #f44336;
}

.save-button:hover {
    background: #45a049;
}

.cancel-button:hover {
    background: #e53935;
}

.edit-icon {
    cursor: pointer;
    color: #aaa;
    background: white;
    border-radius: 50%;
    padding: 2px;
    font-size: 1em; /* Adjust size as needed */
    transition: color 0.3s ease;
}

.edit-icon:hover {
    color: #000;
}

.photo-edit-icon {
    position: absolute;
    top: 5px; /* Adjusted position */
    right: 5px; /* Adjusted position */
}

.username-edit-icon {
    margin-left: 5px; /* Adjusted to place icon to the right */
}