@import './variables.css';

.admin-dashboard {
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
}

h1, h2 {
    text-align: center;
    color: var(--secondary-color);
}

.section {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: var(--secondary-color);
}

.section button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    cursor: pointer;
    border-radius: 5px;
    font-size: 1em;
    margin-bottom: 10px;
}

.section button:hover {
    background-color: var(--primary-hover-color);
}

.service-form input,
.notification-form input,
.notification-form textarea,
.snapcode-form input,
.service-form select {
    display: block;
    width: calc(100% - 22px);
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.icon-select-container {
    display: flex;
    align-items: center;
}

.icon-select-container select {
    flex: 1;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    appearance: none;
    background: none;
}

.icon-preview {
    font-size: 1.5em;
}

.service-form button,
.notification-form button,
.snapcode-form button {
    padding: 10px 20px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    background-color: #28a745;
    color: var(--secondary-color);
    cursor: pointer;
}

.service-form button:hover,
.notification-form button:hover,
.snapcode-form button:hover {
    background-color: #218838;
}

.service-list,
.snapcode-list {
    list-style: none;
    padding: 0;
}

.service-item,
.snapcode-item {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service-details,
.snapcode-details {
    max-width: 70%;
}

.service-actions button,
.snapcode-item button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.service-actions button:nth-child(1),
.snapcode-item button {
    background-color: var(--primary-color);
    color: var(--secondary-color);
}

.service-actions button:nth-child(1):hover,
.snapcode-item button:hover {
    background-color: var(--primary-hover-color);
}

.service-actions button:nth-child(2) {
    background-color: #dc3545;
    color: var(--secondary-color);
}

.service-actions button:nth-child(2):hover {
    background-color: #c82333;
}