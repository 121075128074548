.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.install-prompt {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://www.lesmechants.com/map-background.jpg') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  z-index: 1000;
  color: white;
}

.install-prompt p {
  margin: 0;
  padding-bottom: 20px;
  font-size: 1.2em;
}

.install-prompt button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.install-prompt button:hover {
  background-color: #2980b9;
}

.install-prompt .install-icon {
  font-size: 5em;
  color: #3498db;
  margin-bottom: 20px;
}

.install-prompt .app-logo {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.instructions {
  text-align: center;
  margin: 20px 0;
}

.instructions img {
  max-width: 80%;
  height: auto;
  margin: 20px 0;
}

.instructions p {
  margin: 10px 0;
  font-size: 1em;
}

.instructions ion-icon {
  vertical-align: middle;
  font-size: 1.2em;
  margin-left: 5px;
}