@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900');
@import './variables.css';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

:root {
    --clr: var(--background-color);
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: var(--clr);
}

.container {
    bottom: 55px;
    position: fixed;
    width: 100%;
    background: var(--clr);
    display: flex;
    z-index: 1000;
}

.geolocation-bar {
    position: fixed;
    top: 0;
    width: 100%;
    background: var(--warning-color);
    color: var(--secondary-color);
    text-align: center;
    padding: 10px;
    z-index: 1000;
}

.navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.navigation ul {
    display: flex;
    justify-content: space-around; /* Use space-around for centering */
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
}

.navigation ul li {
    position: relative;
    list-style: none;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    z-index: 1;
}

.navigation ul li a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-weight: 500;
    text-decoration: none;
}

.navigation ul li a .icon {
    line-height: 75px;
    font-size: 1.5em;
    transition: 0.5s;
    color: var(--clr);
}

.navigation ul li.active a .icon {
    transform: translateY(-32px);
}

.navigation ul li a .text {
    position: absolute;
    color: var(--clr);
    font-weight: 400;
    font-size: 0.75em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    opacity: 0;
    transform: translateY(20px);
}

.navigation ul li.active a .text {
    opacity: 1;
    transform: translateY(10px);
}

.indicator {
    position: absolute;
    top: -50%;
    left: 0;
    width: 70px;
    height: 70px;
    background: var(--primary-color);
    border-radius: 50%;
    border: 6px solid var(--clr);
    transition: 0.5s;
}

.indicator::before,
.indicator::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    background: transparent;
}

.indicator::before {
    left: -22px;
    border-top-right-radius: 20px;
    box-shadow: 1px -10px 0 0 var(--clr);
}

.indicator::after {
    right: -22px;
    border-top-left-radius: 20px;
    box-shadow: -1px -10px 0 0 var(--clr);
}