@import './variables.css';

.plans {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    overflow: hidden; /* Prevent the entire page from scrolling */
    padding: 20px;
}

.featured-snapcodes {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    width: 100%; /* Take the full width */
}

.snapcode {
    flex: 1; /* Each snapcode takes equal space */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.snapcode-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.service-list-container {
    flex: 1; /* Take the remaining height */
    width: 100%;
    overflow-y: auto; /* Make only this part scrollable */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.service-list-container::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}