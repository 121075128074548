@import './variables.css';

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: #222327;
    min-height: 100vh;
    padding-bottom: 80px;
}

.profile h2 {
    color: #000000;
}

.profile-form {
    margin-top: 20px;
    text-align: center;
}

.profile-form input[type="text"] {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 300px;
}

.profile-form input[type="file"] {
    display: none;
}

.trademark, .credits {
    margin-top: auto;
    font-size: 0.8em;
    color: rgb(128, 128, 128);
    text-align: center;
    padding: 40px;
}

.credits a {
    color: var(--primary-color);
}