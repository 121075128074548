@import './variables.css';

.service-link {
    text-decoration: none;
    color: inherit;
}

.service {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background: var(--secondary-color);
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
    overflow: hidden; /* Ensure the overlay doesn't overflow the service box */
    position: relative;
}

.service-icon {
    font-size: 2em;
    margin-bottom: 10px;
    z-index: 1;
}

.service-details {
    text-align: center;
    z-index: 1;
}

.service:hover {
    transform: scale(1.05);
}
