@import './variables.css';

.snapcode-link {
    text-decoration: none;
    color: inherit;
}
.snapcode {
    flex: 1; /* Each snapcode takes equal space */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /*border: 1px solid #ccc;*/
    background: var(--secondary-color);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.snapcode:hover {
    transform: scale(1.05);
}

.snapcode-image {
    width: 100%;
    height: auto;
    max-width: 200px; /* Vous pouvez ajuster cette valeur */
    max-height: 200px; /* Vous pouvez ajuster cette valeur */
    border-radius: 10px;
    object-fit: cover; /* Assure un redimensionnement proportionnel de l'image */
}